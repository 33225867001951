import {React, Fragment, useState, useEffect} from "react";
import './RemainderEmail.scss'
import FocusBucket from '../../../assets/icons/focusBucket.svg';
import unFocusBucket from '../../../assets/icons/mailbucket.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import { Menu, Transition } from '@headlessui/react';
import axios from 'axios';
import { toastify } from '../../Toast';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function RemainderEmail() {
  const [bucketList,setBucketList] = useState([]);
  const [bookList,setBookList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [bucketCreated,setBucketCreated] = useState(false);
  const [bucketReminder,setBucketReminder] = useState(false);
  const [bookReminder,setBookReminder] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsCheck, setSelectedItemsCheck] = useState([]);
  const [selectedBookItems, setSelectedBookItems] = useState([]);
  const [selectedBookItemsCheck, setSelectedBookItemsCheck] = useState([]);
  const [timeFiltersText, setTimeFiltersText] = useState('Select frequency');
  const [timeFrequency, setTimeFrequency] = useState('Daily');
  const [noCards, setNoCards] = useState(0);
  const [noHighlights, setNoHighlights] = useState(0);
  const [days, setDaysFrequency] = useState([{key:'S', value:'Sunday'},{key:'M', value:'Monday'},{key:'T', value:'Tuesday'},{key:'W', value:'Wednesday'},{key:'T', value:'Thursday'},{key:'F', value:'Friday'},{key:'S', value:'Saturday'}]);
  console.log(days);
  const [selectedDays, setSelectedDays] = useState([]);
  useEffect(()=>{
    const token = localStorage.getItem('token'); 
    axios.get(`${process.env.REACT_APP_API}reminderEmail/reminder-settings`,{
        headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    .then(function (response) {
      console.log(response);
      setIsLoading(false);
      if(response.data.data.bookDetails.bookList.length){
        setBookList(response.data.data.bookDetails.bookList);
        setNoHighlights(response.data.data.bookDetails.numberOfItems);
        setBookReminder(response.data.data.bookDetails.status);
        const focusedBooks = response.data.data.bookDetails.bookList.filter((book) => book.isFocused);
        const focusedBookIds = focusedBooks.map((book) => book._id);
        setSelectedBookItems(focusedBookIds);
        const checkedBooks = response.data.data.bookDetails.bookList.filter((book) => book.isCheck && !book.isFocused);
        const checkedBookIds = checkedBooks.map((book) => book._id);
        setSelectedBookItemsCheck(checkedBookIds);
      }
      if(response.data.data.bucketDetails.bucketList.length){
        setBucketList(response.data.data.bucketDetails.bucketList);
        setNoCards(response.data.data.bucketDetails.numberOfItems);
        setBucketReminder(response.data.data.bucketDetails.status);
        // setTimeFrequency(response.data.data.timeFrequency.length ? response.data.data.timeFrequency[0] == 'Daily' ?  response.data.data.timeFrequency[0] : '': '');
        // Find all the focused buckets and extract their IDs
        const focusedBuckets = response.data.data.bucketDetails.bucketList.filter((bucket) => bucket.isFocused);
        const focusedIds = focusedBuckets.map((bucket) => bucket._id);
        setSelectedItems(focusedIds);
        const checkedBuckets = response.data.data.bucketDetails.bucketList.filter((bucket) => bucket.isCheck && !bucket.isFocused);
        const checkedIds = checkedBuckets.map((bucket) => bucket._id);
        setSelectedItemsCheck(checkedIds);
      }
      if(response.data.data.timeFrequency.length){
        setTimeFiltersText(response.data.data.timeFrequency.length ? response.data.data.timeFrequency[0] : 'Select frequency');
        if(response.data.data.timeFrequency[0] == 'Daily'){
          setTimeFrequency(response.data.data.timeFrequency[0]);
        }else{
          setTimeFrequency('Weekly');
          // const focusedBuckets = response.data.data.timeFrequency.filter((day) => day);
          setSelectedDays(response.data.data.timeFrequency);
          const updatedDays = days.map(day => {
            if (response.data.data.timeFrequency.includes(day.value)) {
              return { ...day, active: true };
            } else {
              return { ...day, active: false };
            }
          });
          setDaysFrequency(updatedDays);
        //   setDaysFrequency((days) => {
        //     return days.map((dayz) => {
        //        if(dayz.value === day) {
        //            return {...dayz, active: !dayz.active}
        //        }
        //        return dayz;  
        //     });
        //  })
        }
      }
    })
    .catch(function (error) {
      console.log(error);
    });
 },[bucketCreated])


 const handleBucketSubmit = async () => {
  const token = localStorage.getItem('token');
  const userData = JSON.parse(localStorage.getItem('userData'));
  console.log(userData._id,"userData");
  axios.post(`${process.env.REACT_APP_API}reminderEmail/set`, 
    [
      {
        userId:`${userData._id}`,
        type:"Bucket",
        numberOfItems: noCards,
        deliveryType: timeFrequency == 'Daily' ? [timeFrequency] : selectedDays,
        items: selectedItemsCheck,
        focusedItems: selectedItems,
        status:bucketReminder
      },
      {
        userId:`${userData._id}`,
        type:"Kindle",
        numberOfItems: noHighlights,
        deliveryType: timeFrequency == 'Daily' ? [timeFrequency] : selectedDays,
        items: selectedBookItemsCheck,
        focusedItems: selectedBookItems,
        status:bookReminder
      }
    ]
    ,{
      headers: {
      Authorization: `Bearer ${token}`,
    }
    })
    .then(function (response) {
      toastify("success", response.data.message );
      // setIsLoading(false);
      // setCardList(response.data.data);
      // reorder(response.data.data);
      // setBucketList(response.data.data.bucketList);
      // setTotalBuckets(response.data.data.total);
      // console.log(cardList,"cardList");
    })
  .catch(function (error) {
    console.log(error);
    toastify("error", "Error" );
  });
 }

 const toggleDay = (day) => {
  if (selectedDays.includes(day)) {
    // If the day is already selected, remove it
    setSelectedDays(selectedDays.filter((d) => d !== day));
    console.log(days,day,"days")
    // days.map((dayz) => {
    //         if(dayz.value === day) {
    //           console.log(dayz.active,"day....------56");
    //           console.log(!dayz.active,"day....------56");
    //             // return {...day, active: !day.active}
    //         }  
    //      });
    setDaysFrequency((days) => {
       return days.map((dayz) => {
          if(dayz.value === day) {
              return {...dayz, active: !dayz.active}
          }
          return dayz;  
       });
    })
  } else {
    // If the day is not selected, add it
    setSelectedDays([...selectedDays, day]);
    console.log(days,day,"days else")
  //   days.map((dayz) => {
  //     console.log(dayz.value,day,"day value")
  //     if(dayz.value === day) {
  //       console.log(!dayz.active,"day....------98");
  //         // return {...day, active: !day.active}
  //     }  
  //  });
    setDaysFrequency((days) => {
      return days.map((dayz) => {
         if(dayz.value === day) {
             return {...dayz, active: !dayz.active}
         }
         return dayz;  
      });
   })
  }
};

console.log(selectedDays,"selectedDays");

 const handleUnFocusClick = async (bucketId) => {
    console.log(bucketId,"bucketId");
    const updatedItems = selectedItems.filter((item) => item !== bucketId);
    setSelectedItems(updatedItems);

    setBucketList((bucketList) => {
        return bucketList.map((bucket) => {
          if (bucket._id === bucketId) {
            // Toggle the extraData value
            return { ...bucket, focus: bucket.isFocused? !bucket.isFocused : !bucket.focus, isFocused: bucket.focus? !bucket.focus : !bucket.isFocused, isCheck: false  };
          }
          return bucket;
        });
      });
 }

 const handleFocusClick = async (bucketId) => {
    console.log(bucketId,"bucketId");
    if(selectedItemsCheck.length){
      const updatedItems = selectedItemsCheck.filter((item) => item !== bucketId)
      setSelectedItemsCheck(updatedItems);
    }
    
    if(selectedItems.length < 2){
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, bucketId]);
      const selectedItem = bucketList.find((bucket) => bucket._id === bucketId);
      // Push extra data or modify the selected item as needed
      selectedItem.focus = true;
      // Update the state with the modified item
      setBucketList((bucketList) => {
        return bucketList.map((bucket) => (bucket._id === bucketId ? selectedItem : bucket));
      });
    }else{
        console.log("you can not focus more than 2 buckets");
        toastify("error", "you can not focus more than 2 buckets" );
    }
  };

  const handleUnFocusBookClick = async (bucketId) => {
    console.log(bucketId,"bucketId");
    const updatedItems = selectedBookItems.filter((item) => item !== bucketId);
    setSelectedBookItems(updatedItems);

    setBookList((bookList) => {
        return bookList.map((bucket) => {
          if (bucket._id === bucketId) {
            // Toggle the extraData value
            return { ...bucket, focus: bucket.isFocused? !bucket.isFocused : !bucket.focus, isFocused: bucket.focus? !bucket.focus : !bucket.isFocused, isCheck: false  };
          }
          return bucket;
        });
      });
 }

 const handleFocusBookClick = async (bucketId) => {
    console.log(bucketId,"bucketId");
    if(selectedItemsCheck.length){
      const updatedItems = selectedItemsCheck.filter((item) => item !== bucketId)
      setSelectedBookItemsCheck(updatedItems);
    }
    
    if(selectedBookItems.length < 2){
      setSelectedBookItems((prevSelectedItems) => [...prevSelectedItems, bucketId]);
      const selectedItem = bookList.find((bucket) => bucket._id === bucketId);
      // Push extra data or modify the selected item as needed
      selectedItem.focus = true;
      // Update the state with the modified item
      setBookList((bookList) => {
        return bookList.map((bucket) => (bucket._id === bucketId ? selectedItem : bucket));
      });
    }else{
        console.log("you can not focus more than 2 books");
        toastify("error", "you can not focus more than 2 books" );
    }
  };

  const handleCheckboxChange = (event,bucket) => {
    console.log(event,bucket,"event");
    const value = event.target.value;
    if (event.target.checked) {
      event.target.checked = true;
      setSelectedItemsCheck((prevSelectedItems) => [...prevSelectedItems, value]);
    } else {
      // checkbox.checked = false;
      setSelectedItemsCheck((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item !== value)
      );
    }

    const updatedBuckets = bucketList.map((b) => {
      if (b._id === bucket._id) {
        return { ...b, isCheck: !b.isCheck };
      }
      return b;
    });
    setBucketList(updatedBuckets);
  };

  const handleCheckboxChangeBooks = (event,bucket) => {
    console.log(event,bucket,"event");
    const value = event.target.value;
    if (event.target.checked) {
      event.target.checked = true;
      setSelectedBookItemsCheck((prevSelectedItems) => [...prevSelectedItems, value]);
    } else {
      // checkbox.checked = false;
      setSelectedBookItemsCheck((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item !== value)
      );
    }

    const updatedBuckets = bookList.map((b) => {
      if (b._id === bucket._id) {
        return { ...b, isCheck: !b.isCheck };
      }
      return b;
    });
    setBookList(updatedBuckets);
  };
  const handleAllBooksChange = (event) => {
    const value = event.target.value;
    if(event.target.checked){
      var checkboxes = document.querySelectorAll('.books-section input[type="checkbox"]');
      console.log(checkboxes.length,"checkbox length");
      var checkedValues = []; // Array to store the values of checked checkboxes
      checkboxes.forEach(function(checkbox) {
        if(!checkbox.checked){
          var checked = true; // Replace with your desired checked state
          checkbox.checked = checked;
          if (checkbox.checked) {
            checkedValues.push(checkbox.value);
            setSelectedBookItemsCheck((prevSelectedItems) => [...prevSelectedItems, checkbox.value]);
          }
        }
      });
      const updatedBuckets = bookList.map((b) => {
        if (!b.isFocused || !b.focus) {
          console.log("focus buckets");
          return { ...b, isCheck: true };
        }
        return b;
      });
      setBookList(updatedBuckets);
    }else{
      var checkboxes = document.querySelectorAll('.books-section input[type="checkbox"]');
      var checkedValues = []; // Array to store the values of checked checkboxes

      checkboxes.forEach(function(checkbox) {
        if(checkbox.checked){
          var checked = false; // Replace with your desired checked state
          checkbox.checked = checked;
          setSelectedBookItemsCheck([]);
        }
        
      });
      const updatedBuckets = bookList.map((b) => {
        if (!b.isFocused || !b.focus) {
          console.log("focus buckets");
          return { ...b, isCheck: false };
        }
        return b;
      });
      setBookList(updatedBuckets);
    }
  }
  const handleAllBucketsChange = (event) => {
    const value = event.target.value;
    if(event.target.checked){
      var checkboxes = document.querySelectorAll('.buckets-section input[type="checkbox"]');
      console.log(checkboxes.length,"checkbox length");
      var checkedValues = []; // Array to store the values of checked checkboxes
      checkboxes.forEach(function(checkbox) {
        if(!checkbox.checked){
          var checked = true; // Replace with your desired checked state
          checkbox.checked = checked;
          if (checkbox.checked) {
            checkedValues.push(checkbox.value);
            setSelectedItemsCheck((prevSelectedItems) => [...prevSelectedItems, checkbox.value]);
          }
        }
      });
      const updatedBuckets = bucketList.map((b) => {
        if (!b.isFocused || !b.focus) {
          console.log("focus buckets");
          return { ...b, isCheck: true };
        }
        return b;
      });
      setBucketList(updatedBuckets);
    }else{
      var checkboxes = document.querySelectorAll('.buckets-section input[type="checkbox"]');
      var checkedValues = []; // Array to store the values of checked checkboxes

      checkboxes.forEach(function(checkbox) {
        if(checkbox.checked){
          var checked = false; // Replace with your desired checked state
          checkbox.checked = checked;
          setSelectedItemsCheck([]);
        }
        
      });
      const updatedBuckets = bucketList.map((b) => {
        if (!b.isFocused || !b.focus) {
          console.log("focus buckets");
          return { ...b, isCheck: false };
        }
        return b;
      });
      setBucketList(updatedBuckets);
    }
  }
  const handleBucketsKeyDown = (event) => {
    // Allow only number keys and control keys (e.g., Backspace, Delete, Arrow keys)
    if (
      !(
        (event.key >= '0' && event.key <= '9') || // Allow numbers
        event.key === 'Backspace' || // Allow Backspace
        event.key === 'Delete' || // Allow Delete
        event.key === 'ArrowLeft' || // Allow Left Arrow
        event.key === 'ArrowRight' || // Allow Right Arrow
        event.key === 'Tab' // Allow Tab
      )
    ) {
      event.preventDefault(); // Prevent any non-numeric key
      toastify("error", "Type only numbers" );
    }
  };
  const handleKeyDown = (event) => {
    // Allow only number keys and control keys (e.g., Backspace, Delete, Arrow keys)
    if (
      !(
        (event.key >= '0' && event.key <= '9') || // Allow numbers
        event.key === 'Backspace' || // Allow Backspace
        event.key === 'Delete' || // Allow Delete
        event.key === 'ArrowLeft' || // Allow Left Arrow
        event.key === 'ArrowRight' || // Allow Right Arrow
        event.key === 'Tab' // Allow Tab
      )
    ) {
      event.preventDefault(); // Prevent any non-numeric key
      toastify("error", "Type only numbers" );
    }
  };
  const handleNoCardsChange = (event) => {
    const value = event.target.value;
    setNoCards(value);
    if(value > 10){
      setNoCards(10);
      toastify("error", "Maximum cards to choose is 10" );
    }
  }

  const handleNoCardsBlur = (event) => {
    const value = event.target.value;
    setNoCards(value);
    if(value < 1){
      setNoCards(1);
      toastify("error", "Minimum highlights to choose is 1" );
    }
  }

  const handleNoHighlightsChange = (event) => {
    const value = event.target.value;
    setNoHighlights(value);
    if(value > 10){
      setNoHighlights(10);
      toastify("error", "Maximum highlights to choose is 10" );
    }
  }

  const handleNoHighlightsBlur = (event) => {
    const value = event.target.value;
    setNoHighlights(value);
    if(value < 1){
      setNoHighlights(1);
      toastify("error", "Minimum highlights to choose is 1" );
    }
  }

  const handleFrequency = (value) => {
    setTimeFrequency(value);
  }

  const handleReminderBucketsChange = async() => {
    setBucketReminder(!bucketReminder);
    const token = localStorage.getItem('token');
    const userData = JSON.parse(localStorage.getItem('userData'));
    try {
      const response =  await axios.patch(`${process.env.REACT_APP_API}reminderEmail/update-reminder-settings`, [{type: "Bucket", userId:`${userData._id}`,status:!bucketReminder },{type: "Kindle", userId:`${userData._id}`, status:bookReminder}],
      {
        headers: {
            Authorization: `Bearer ${token}`,
          }
      }
      );
      console.log(response);
      toastify("success", response.data.message );
    } catch (error) {
      console.error(error);
      
    }
  }
  

  const handleReminderBooksChange = async() => {
    setBookReminder(!bookReminder);
    const token = localStorage.getItem('token');
    const userData = JSON.parse(localStorage.getItem('userData'));
    try {
      const response =  await axios.patch(`${process.env.REACT_APP_API}reminderEmail/update-reminder-settings`, [{type: "Bucket", userId:`${userData._id}`,status:bucketReminder }, {type: "Kindle", userId:`${userData._id}`, status:!bookReminder}],
      {
        headers: {
            Authorization: `Bearer ${token}`,
          }
      }
      );
      console.log(response);
      toastify("success", response.data.message );
    } catch (error) {
      console.error(error);
      
    }
  }

  // console.log(bucketList,"bucketList");
  // console.log(selectedItems,"selectedItems");
  // console.log(selectedItemsCheck,"selectedItemsCheck");
  const filterDropDown = [
    { name: 'Daily', href: '#' },
    { name: 'Monday', href: '#' }, 
    { name: 'Tuesday', href: '#' },
    { name: 'Wednesday', href: '#' },
    { name: 'Thursday', href: '#' },
    { name: 'Friday', href: '#' },
    { name: 'Saturday', href: '#' },
    { name: 'Sunday', href: '#' }
  ] 

  const [setbucketFiltersText] = useState(filterDropDown[0].name);
    const handleFilterText = (text) => {
      setTimeFiltersText(text);
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return(
    <>
      {
        isLoading ? (
          <div className='App-loading'>
              <div className='App-logo' title='Zimzee' /> 
            </div>
        ) :
        (
          <>
          <div className="remainderemail-section">
            <div className="flex">
              <div className="selectreminder-content">Select the buckets for reminder emails</div>
              <div className="reminder-content">
                <div className="flex">
                  <div className="turnreminder-content">Turn Reminders On/Off</div>
                  <div>
                  <label class="switch">
                    <input type="checkbox" checked={bucketReminder? true : false} onChange={handleReminderBucketsChange}/>
                    <span class="slider round"></span>
                  </label>
                  </div> 
                </div> 
              </div>
            </div>
            <div className="focusbucket-content">A max of 2 focus buckets allowed.</div>
            <div className="flex">
              <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" onChange={handleAllBucketsChange} />
              <div className="selectoption-content ml-2" for="buckets">Select all buckets</div>
            </div>
            <div className="selectedbucket-content">{selectedItemsCheck.length} buckets selected</div>
            <div className="remainderselected-content">
              {
                bucketList.length != 0 ? 
                bucketList?.length?
                bucketList.map((bucket, index)=>(
                <div className={(bucket?.focus || bucket?.isFocused)? `focusbucketwholeselected-section flex`:`focusbucketwhole-section buckets-section flex`}>
                  <button type="button" onClick={() => (bucket?.focus || bucket?.isFocused)? handleUnFocusClick(bucket._id) : handleFocusClick(bucket._id)}><img className="focusbucketmail-imgcontent" src={(bucket?.focus || bucket?.isFocused)? FocusBucket : unFocusBucket} alt="FocusBucket" /></button>
                  {
                    !(bucket?.focus || bucket?.isFocused) && (
                      <input className="inputfocus-content" type="checkbox" name="buckets" value={bucket._id} onChange={(e) => handleCheckboxChange(e,bucket)} checked={bucket?.isCheck? true : false} />
                    ) 
                  }
                  {
                    bucket.name.length > 19 ? (
                      <div class="tooltip" style={{display: "flex"}}><span class="tooltipkindle-text bucket"><p>{bucket.name}</p></span><div className="focusbucketname-content">{bucket.name}</div></div>
                    ) : (
                      <div className="focusbucketname-content">{bucket.name}</div>
                    )
                  }
                  
                  <div className={(bucket?.focus || bucket?.isFocused)? `focuscountselected-content` : `focuscount-content`}>{bucket.cards.length}</div>
                </div>
                 )) : "No Buckets" : "No Buckets"
              }
                {/* <div className="focusbucketwholeselected-section flex">
                  <img src={unFocusBucket} alt="FocusBucket" />
                  <div className="focusbucketname-content">Fashion '23</div>
                  <div className="focuscountselected-content">6</div>
                </div>
                <div className="focusbucketwholeselected-section flex">
                  <img src={FocusBucket} alt="FocusBucket" />
                  <div className="focusbucketname-content">Facebook</div>
                  <div className="focuscountselected-content">19</div>
                </div>
                <div className="focusbucketwhole-section flex">
                  <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" />
                  <div className="focusbucketname-content">Instagram</div>
                  <div className="focuscount-content">6</div>
                </div>
                <div className="focusbucketwhole-section flex">
                  <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" />
                  <div className="focusbucketname-content">Media</div>
                  <div className="focuscount-content">210</div>
                </div>
                <div className="focusbucketwhole-section flex">
                  <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" />
                  <div className="focusbucketname-content">Ads</div>
                  <div className="focuscount-content">10</div>
                </div>
                <div className="focusbucketwhole-section flex">
                  <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" />
                  <div className="focusbucketname-content">Marketing</div>
                  <div className="focuscount-content">58</div>
                </div>
                <div className="focusbucketwhole-section flex">
                  <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" />
                  <div className="focusbucketname-content">My Collections</div>
                  <div className="focuscount-content">58</div>
                </div>
                <div className="focusbucketwhole-section flex">
                  <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" />
                  <div className="focusbucketname-content">Movie</div>
                  <div className="focuscount-content">48</div>
                </div>
                <div className="focusbucketwhole-section flex">
                  <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" />
                  <div className="focusbucketname-content">important project notes</div>
                  <div className="focuscount-content">8</div>
                </div>
                <div className="focusbucketwhole-section flex">
                  <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" />
                  <div className="focusbucketname-content">Instagram testing</div>
                  <div className="focuscount-content">15</div>
                </div>
                <div className="focusbucketwhole-section flex">
                  <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" />
                  <div className="focusbucketname-content">roy2</div>
                  <div className="focuscount-content">210</div>
                </div>
                <div className="focusbucketwhole-section flex">
                  <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" />
                  <div className="focusbucketname-content">new bucket roy</div>
                  <div className="focuscount-content">210</div>
                </div>
                <div className="focusbucketwhole-section flex">
                  <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" />
                  <div className="focusbucketname-content">My research</div>
                  <div className="focuscount-content">10</div>
                </div>
                <div className="focusbucketwhole-section flex">
                  <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" />
                  <div className="focusbucketname-content">my test</div>
                  <div className="focuscount-content">5</div>
                </div>
                <div className="focusbucketwhole-section flex">
                  <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" />
                  <div className="focusbucketname-content">Apple-roy</div>
                  <div className="focuscount-content">18</div>
                </div>
                <div className="focusbucketwhole-section flex">
                  <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" />
                  <div className="focusbucketname-content">circus</div>
                  <div className="focuscount-content">6</div>
                </div>
                <div className="focusbucketwhole-section flex">
                  <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" />
                  <div className="focusbucketname-content">twitter savings</div>
                  <div className="focuscount-content">15</div>
                </div>
                <div className="focusbucketwhole-section flex">
                  <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" />
                  <div className="focusbucketname-content">01/01/20</div>
                  <div className="focuscount-content">18</div>
                </div>
                <div className="focusbucketwhole-section flex">
                  <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" />
                  <div className="focusbucketname-content">Adams fashion bucket</div>
                  <div className="focuscount-content">18</div>
                </div>
                <div className="focusbucketwhole-section flex">
                  <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" />
                  <div className="focusbucketname-content">Marketing general</div>
                  <div className="focuscount-content">18</div>
                </div>
                <div className="focusbucketwhole-section flex">
                  <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" />
                  <div className="focusbucketname-content">Instagram</div>
                  <div className="focuscount-content">6</div>
                </div> */}
            </div>
            <div className="myselectedfocus-content">I Want <input type="text" placeholder="0" className="focusselectedcount-content" value={noCards} onKeyDown={handleBucketsKeyDown} onChange={handleNoCardsChange} onBlur={handleNoCardsBlur}/> cards from my selected buckets (Maximum cards to choose is 10)</div>
            <hr/>


            <div className="flex kindlefocus-content">
              <div className="selectreminder-content">Select the books (Kindle)  for reminder emails</div>
              <div className="reminder-content">
                <div className="flex">
                  <div className="turnreminder-content">Turn Reminders On/Off</div>
                  <div>
                  <label class="switch">
                    <input type="checkbox" checked={bookReminder? true : false} onChange={handleReminderBooksChange}/>
                    <span class="slider round"></span>
                  </label>
                  </div> 
                </div>
              </div>
            </div>
            <div className="flex selectedkindlebooks-content">
              <input className="inputfocus-content" type="checkbox" id="books" name="books" value="Books" onChange={handleAllBooksChange} />
              <div className="selectoption-content ml-2" for="buckets">Select all Books</div>
            </div>
            <div className="selectedbucket-content">{selectedBookItemsCheck.length} books selected</div>
            <div className="remainderselected-content">
            {
                bookList.length != 0 ? 
                bookList?.length?
                bookList.map((bucket, index)=>(
                <div className={(bucket?.focus || bucket?.isFocused)? `focusbucketwholeselected-section flex`:`focusbucketwhole-section books-section flex`}>
                  <button type="button" onClick={() => (bucket?.focus || bucket?.isFocused)? handleUnFocusBookClick(bucket._id) : handleFocusBookClick(bucket._id)}><img className="focusbucketmail-imgcontent" src={(bucket?.focus || bucket?.isFocused)? FocusBucket : unFocusBucket} alt="FocusBucket" /></button>
                  {
                    !(bucket?.focus || bucket?.isFocused) && (
                      <input className="inputfocus-content" type="checkbox" name="book" value={bucket._id} onChange={(e) => handleCheckboxChangeBooks(e,bucket)} checked={bucket?.isCheck? true : false} />
                    ) 
                  }
                  {
                    bucket.title.length > 19 ? (
                      <div class="tooltip" style={{display: "flex"}}><span class="tooltipkindle-text bucket"><p>{bucket.title}</p></span><div className="focusbucketname-content">{bucket.title}</div></div>
                    ) : (
                      <div className="focusbucketname-content">{bucket.title}</div>
                    )
                  }
                  <div className={(bucket?.focus || bucket?.isFocused)? `focuscountselected-content` : `focuscount-content`}>{bucket.totalHighlightOfBook}</div>
                </div>
                 )) : "No Books" : "No Books"
              }
              {/* <div className="focusbucketwholeselected-section flex">
                <img src={FocusBucket} alt="FocusBucket" />
                <div className="focusbucketname-content">The art of saying no</div>
                <div className="focuscountselected-content">2</div>
              </div>
              <div className="focusbucketwholeselected-section flex">
                <img src={FocusBucket} alt="FocusBucket" />
                <div className="focusbucketname-content">Harry potter and the philosopher's stone</div>
                <div className="focuscountselected-content">4</div>
              </div>
              <div className="focusbucketwhole-section flex">
                <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" />
                <div className="focusbucketname-content">How to win friends and influence people</div>
                <div className="focuscount-content">4</div>
              </div>
              <div className="focusbucketwhole-section flex">
                <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" />
                <div className="focusbucketname-content">Dopamine Detox</div>
                <div className="focuscount-content">6</div>
              </div>
              <div className="focusbucketwhole-section flex">
                <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" />
                <div className="focusbucketname-content">The Monk who sold his ferrari</div>
                <div className="focuscount-content">6</div>
              </div>
              <div className="focusbucketwhole-section flex">
                <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" />
                <div className="focusbucketname-content">All the songs: The story behind every Beatles release</div>
                <div className="focuscount-content">8</div>
              </div>
              <div className="focusbucketwhole-section flex">
                <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" />
                <div className="focusbucketname-content">Pride and Prejudice</div>
                <div className="focuscount-content">2</div>
              </div>
              <div className="focusbucketwhole-section flex">
                <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" />
                <div className="focusbucketname-content">The Book Thief</div>
                <div className="focuscount-content">2</div>
              </div>
              <div className="focusbucketwhole-section flex">
                <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" />
                <div className="focusbucketname-content">Kite Runner</div>
                <div className="focuscount-content">6</div>
              </div>
              <div className="focusbucketwhole-section flex">
                <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" />
                <div className="focusbucketname-content">2 Billion Under 20: How Millennials are Breaking Down Age Barriers and Changing the World</div>
                <div className="focuscount-content">1</div>
              </div>
              <div className="focusbucketwhole-section flex">
                <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" />
                <div className="focusbucketname-content">Lessons in Chemistry</div>
                <div className="focuscount-content">2</div>
              </div>
              <div className="focusbucketwhole-section flex">
                <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" />
                <div className="focusbucketname-content">21 Lessons for the 21st Century</div>
                <div className="focuscount-content">2</div>
              </div>
              <div className="focusbucketwhole-section flex">
                <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" />
                <div className="focusbucketname-content">Anyway You Can: Doctor Bosworth shares her mom's cancer journey. A Beginners Guide to Ketones for Life</div>
                <div className="focuscount-content">1</div>
              </div>
              <div className="focusbucketwhole-section flex">
                <input className="inputfocus-content" type="checkbox" id="buckets" name="buckets" value="Buckets" /> 
                <div className="focusbucketname-content">Florence Nightingale: A life from beginning to end</div>
                <div className="focuscount-content">6</div>
              </div>   */}
            </div>
            <div className="myselectedfocus-content">I Want <input type="text" placeholder="0" className="focusselectedcount-content" value={noHighlights} onKeyDown={handleKeyDown} onChange={handleNoHighlightsChange} onBlur={handleNoHighlightsBlur}/> highlights from my selected books (Maximum highlights to choose is 10)</div>
            <hr/>
            <div className="time-content">Frequency:</div>
            <div className="repeat-content">Repeat</div>
            <div className="flex repeat-section">
              <button className={timeFrequency == 'Daily' ? 'repeatselectheading-content' : 'repeatheading-content'} onClick={() => handleFrequency('Daily')}>
                <span className="daily-context">Daily</span>
              </button>
              <button className={timeFrequency == 'Weekly' ? 'repeatselectheading-content' : 'repeatheading-content'} onClick={() => handleFrequency('Weekly')}>
                <span className="daily-context">Weekly</span>
              </button> 
              {/* <button className="repeatheading-content">
                <span className="daily-context">Monthly</span>
              </button>  */}
            </div>
            {
              timeFrequency == 'Weekly' ? (
                <>
                  <div className="repeat-content">On these days</div>
                  <div className="flex repeat-section">
                    {
                      days?.map((day) => (
                        <button className={day.active ? 'daysSelectheading-content' : 'daysheading-content'} onClick={() => toggleDay(day.value)}>
                          <span className="days-context">{day.key}</span>
                        </button>
                        ))
                    }
                    {/* <button className="daysheading-content">
                      <span className="days-context">S</span>
                    </button>
                    <button className="daysheading-content">
                      <span className="days-context">M</span>
                    </button>
                    <button className="daysSelectheading-content">
                      <span className="days-context">T</span>
                    </button>
                    <button className="daysheading-content">
                      <span className="days-context">W</span>
                    </button>
                    <button className="daysheading-content">
                      <span className="days-context">T</span>
                    </button>
                    <button className="daysSelectheading-content">
                      <span className="days-context">F</span>
                    </button>
                    <button className="daysheading-content">
                      <span className="days-context">S</span>
                    </button> */}
                  </div>
                </>
              )
               : ""
            }
            
            <div className="lastsection-content">
              <div className="flex remainderlast-section">
                <button style={{filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))'}} className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white" onClick={handleBucketSubmit}>
                  <span className="emailbuckets-context">Save Settings</span>
                </button> 
                <div className="cancel-content">Cancel</div>
              </div> 
            </div>      
        </div>
        <ToastContainer />
        </>
        )
      }
    </>
    
  );
}
export default RemainderEmail;