import {React,Fragment,useState} from "react";
import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import './KindleList.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fontIcons from '@fortawesome/free-solid-svg-icons';
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import TrashIcon from "../../../assets/icons/trash_icon.svg";
import CalenderIcon from '../../../assets/icons/calendar.svg'; 
import ArtBook from "../../../assets/images/artBook.png";
import Detox from "../../../assets/images/detox.png";
import Harry from "../../../assets/images/harry.png";
import Morrie from "../../../assets/images/morrie.png";
import Thief from "../../../assets/images/thief.png";
import Florence from "../../../assets/images/florence.png";
import Power from "../../../assets/images/power.png";
import Chanakya from "../../../assets/images/chanakya.png";
import Friends from "../../../assets/images/friends.png";
import Monk from "../../../assets/images/monk.png";
import EmailGrey from "../../../assets/icons/emailGrey.svg";
import { Menu, Transition } from '@headlessui/react';
import { toastify } from '../../../components/Toast';
import { ToastContainer, toast } from 'react-toastify';
import DismissButton from "../../../assets/icons/dismissButton.svg";
import FocusBucket from "../../../assets/icons/focusBucket.svg";

const KindleList = ({ userData, fetchUserAuth }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [kindleCreated, setKindleCreated] = useState(false);
  const [kindles, setKindle] = useState([]);
  const [cardFilterDefault, setCardFilterDefault] = useState('Most_Recent');
  const [cardLabelDefault, setLabelFilterDefault] = useState('Most Recent');
  const [invitepopup, setInvitePopup] = useState(false);
  const [stopSyncpopup, setStopSyncPopup] = useState(false);
  const [syncpopup, setSyncPopup] = useState(false);
  const [kindleAutoSync, setKindleAutoSync] = useState(false);
  const [kindleAutoSyncCreated, setKindleAutoSyncCreated] = useState(false);
  useEffect(() => {
    // Function to fetch data from the API and update the state
    const token = localStorage.getItem("token");
    console.log(kindles,"kindles---");
    // console.log(userData,"userData----kindle---list----------");
    // setKindleAutoSync(userData ? userData?.kindleAutoSync? userData.kindleAutoSync : false : false);
    const fetchBuckets = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}books/list?filterValue=${cardFilterDefault}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        setIsLoading(false);
        setKindle(response?.data?.data); // Assuming the API response is an array of bucket objects
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    };
    if (token) {
      fetchBuckets();
    }
  }, [kindleCreated]);

  useEffect(() => {
    const fetchUserAuth = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}users/me`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setKindleAutoSync(response?.data?.data?.kindleAutoSync);
      } catch (error) {
        console.error(error);
      }
    }
    fetchUserAuth();
  }, [kindleAutoSyncCreated]);
  const filterDropDown = [
    { name: 'Most Recent', href: '#' },
    { name: 'Most Highlights', href: '#' },
    { name: 'Book Title', href: '#' }, 
    { name: 'Author Name', href: '#' },
  ]

  const filterDropDownselect = [
    { name: kindleAutoSync ? 'Stop Sync' : 'Start Sync', href: '#' },
    { name: 'Delete all', href: '#' },
  ]
  console.log(kindleAutoSync,"kindleAutoSync");

  const [setFiltersText] = useState(filterDropDown[0].name);

  const [setFiltersSelectText] = useState(filterDropDownselect[0].name);

  const handleFilterText = (text) => {
    console.log(text, "handlefiltertext");
    setIsLoading(true);
    setLabelFilterDefault(text);
    // setFiltersText(text);
    const filterValues =
      text == "Most Recent"
        ? "Most_Recent"
        : text == "Book Title"
        ? "Book_Title"
        : text == "Author Name"
        ? "Author_Name"
        : text == "Most Highlights"
        ? "Most_Highlights"
        : "";
    // localStorage.setItem("cardFilter", filterValues);
    setCardFilterDefault(filterValues);
    cardUpdate();
    }
    function cardUpdate() {
      setKindleCreated(!kindleCreated);
    }
    const handleFilterSelectText = (text) => {
      // setFiltersSelectText(text);
      if(text == 'Delete all'){
        setInvitePopup(true);
      }
      if(text == 'Stop Sync'){
        setStopSyncPopup(true);
      }
      if(text == 'Start Sync'){
        setStopSyncPopup(true);
      }
    }
    const handleInvitePopup = () => {
      setInvitePopup(true);
    }
    const closeInvitePopup = () => {
      setInvitePopup(false);
    }
    const handleSyncPopup = () => {
      setSyncPopup(true);
    }
    const closeSyncPopup = () => {
      setSyncPopup(false);
    }
    const closeStopSyncPopup = () => {
      setStopSyncPopup(false);
    }

    const sanitizeTitle = (title) => {
      return title.replace(/[&#'"]/g, ""); // Replace & # ' " with an empty string
    };

    const decodeHtmlEntities = (text) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(text, 'text/html');
      return doc.documentElement.textContent;
    };

    const handleFocusClick = async (event,bucketId,focus) => {
      event.stopPropagation(); // Stop the event from bubbling to the parent (anchor tag)
      event.preventDefault(); // Prevent redirection
      // console.log(kindles);
      const focusItems = kindles.bookList.filter(item => item.isFocused);
      console.log(focusItems);
      const selectedItem =  kindles.bookList.find(item => item._id === bucketId);
      console.log(selectedItem,"selectedItem");
      if (!selectedItem.isFocused && focusItems.length >= 2) {
        // User cannot select more than two focus items
        // alert("You can't select more than two focus items!");
        toastify("error", "A maximum of two focus books is allowed");
        return;
      }
      // setIsFocused(!isFocused);
      // await updateBucket(bucketId, !isFocused, "focus");
      const userData = JSON.parse(localStorage.getItem('userData'));
    const token = localStorage.getItem('token');
     try {
       const response = await axios.patch(`${process.env.REACT_APP_API}books/update`, {bookId:bucketId,isFocused:!focus},
       {
         headers: {
             Authorization: `Bearer ${token}`,
           }
       }
       );
       console.log(response);
       toastify("success", response.data.message);
       cardUpdate();
      //  bucketUpdate();
      //  setIsLoading(false);
     } catch (error) {
       console.error(error);
       toastify("error", error.response.data.message );
     }
    };
    
    const updateKindleStatus = async () => {
        const token = localStorage.getItem('token');
        
        try {
              const response = await axios.post(`${process.env.REACT_APP_API}books/update-kindle-status`,
                {status: kindleAutoSync ? false : true},
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  }
                }
            );
            console.log(response);
            toastify("success", response.data.message);
            setKindleAutoSyncCreated(!kindleAutoSyncCreated);
            cardUpdate();
            setStopSyncPopup(false);
        } catch (error) {
          console.error(error);
          toastify("error", error.response.data.message );
        }
    }
    
    const deleteUserAccount = async () => {
      const token = localStorage.getItem('token');
      try {
            const response = await axios.delete(`${process.env.REACT_APP_API}books/delete-all-books`,
              {
                headers: {
                    Authorization: `Bearer ${token}`,
                  }
              }
          );
          console.log(response);
          toastify("success", response.data.message);
          cardUpdate();
          setInvitePopup(false);
      } catch (error) {
        console.error(error);
        toastify("error", error.response.data.message );
      }
  }
           
    function classNames(...classes) {
     return classes.filter(Boolean).join(' ')
   }

  return(
    <>
    {isLoading ? (
        <div className="App-loading">
          <div className="App-logo" title="Zimzee" />
        </div>
      ) : (
    <div>
      <div className="kindleList-wholesection">
        {/* <div className="featureprogress-content">Sample data only - This feature is in Progress…</div> */}
        <div className="kindleHeader">
          <div className="flex justify-between mb-3">
            <div className="flex items-center justify-end"> 
              <h2 className="text-xl mybuckets-content dark:text-[#fff]">Kindle Highlights</h2>
              <div className="kindlehighlight-content mr-4"><span className="kindlebg-content background-primary text-white rounded-full ml-4">{kindles?.bookList?.length}</span></div>
                  {/* <FontAwesomeIcon className="vertical-iconcontent text-[#707070] text-[22px] mr-4" icon={faEllipsisVertical} /> */}
                  <Menu as="div" className="relative flex-shrink-0">
                <div>
                  <Menu.Button className="flex rounded-full focus:outline-none ">
                    <div href="#" className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500">
                      <strong><FontAwesomeIcon className="vertical-iconcontent text-[#707070] text-[22px] mr-4" icon={faEllipsisVertical} /></strong>
                    </div>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">                           
                    {filterDropDownselect.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <a
                            onClick={()=>{handleFilterSelectText(item.name)}}
                            href={item.href}
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block py-2 px-4 text-[13px] text-gray-700'
                            )}
                          >
                            {item.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
                  {/* <img className="w-6 trash-icon" src={TrashIcon} alt="trashIcon"/> */}
              {
                kindles?.bookList?
                kindles?.bookList?.length?
                <a style={{filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))',cursor:'pointer'}} className="buttonpadding-content mx-8 text-[16px] rounded-full bg-[#FF6600] text-white pr-[32px]" onClick={handleSyncPopup}>
                <FontAwesomeIcon className="pl-[23px] pr-[17.73px]" icon={fontIcons.faPlus} />
                <span className="createbutton-context">Sync Highlights</span>
              </a>
                :
                <a href="https://read.amazon.com/kp/notebook?myhighlight" style={{filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))'}} className="buttonpadding-content mx-8 text-[16px] rounded-full bg-[#FF6600] text-white pr-[32px]">
                <FontAwesomeIcon className="pl-[23px] pr-[17.73px]" icon={fontIcons.faPlus} />
                <span className="createbutton-context">Sync Highlights</span>
              </a>
                 : 
                 <a href="https://read.amazon.com/kp/notebook?myhighlight" style={{filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))'}} className="buttonpadding-content mx-8 text-[16px] rounded-full bg-[#FF6600] text-white pr-[32px]">
                    <FontAwesomeIcon className="pl-[23px] pr-[17.73px]" icon={fontIcons.faPlus} />
                    <span className="createbutton-context">Sync Highlights</span>
                  </a>
              }
                
              <div className="text-[14px] lg:text-[17px]"><a className="flex items-center" href="/"><img src={CalenderIcon} alt='calenderIcon' className="w-[22px] dark-mode-icon"/> <span className="createdtext-content pl-2 dark:text-[#bbbbbb]">Last synced on {kindles?.lastKindleSync? moment(kindles?.lastKindleSync).format(
                        "MMM D, YYYY @ hh:mm A"
                      ) : "---"}</span></a> </div>             
              </div>
              <div className="ml-auto">
            <div className="ml-[auto] flex items-center justify-end">
              <span className="sorttext-content mx-3 dark:text-[#acacac]">Sort By</span>
              <Menu as="div" className="relative flex-shrink-0">
                <div>
                  <Menu.Button className="flex rounded-full focus:outline-none ">
                    <div href="#" className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500">
                      <strong className="createdtext-content pr-4">{cardLabelDefault}</strong>
                      <FontAwesomeIcon style={{fontSize:'12px',color:'#707070'}} icon={faChevronDown} />
                    </div>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">                           
                    {filterDropDown.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <a
                            onClick={()=>{handleFilterText(item.name)}}
                            href={item.href}
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block py-2 px-4 text-[13px] text-gray-700'
                            )}
                          >
                            
                            {cardLabelDefault == item.name ? item.name : item.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div> 
          </div>          
            </div>
        </div>
        <hr />
        {/* <div className="flex mt-[18px]">
          <div className="cards-filter flex justify-between">
            <div className="flex items-center">
              <div className="flex actionsHolder">            
                <div href="#" className="flex items-center">
                </div>           
              </div>
            </div>
          </div>
          <div className="ml-auto">
            <div className="ml-[auto] flex items-center justify-end">
              <span className="sorttext-content mx-3 dark:text-[#acacac]">Sort By</span>
              <Menu as="div" className="relative flex-shrink-0">
                <div>
                  <Menu.Button className="flex rounded-full focus:outline-none ">
                    <div href="#" className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500">
                      <strong className="createdtext-content pr-4">Most Recent</strong>
                      <FontAwesomeIcon style={{fontSize:'12px',color:'#707070'}} icon={faChevronDown} />
                    </div>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">                           
                    {filterDropDown.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <a
                            onClick={()=>{handleFilterText(item.name)}}
                            href={item.href}
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block py-2 px-4 text-[13px] text-gray-700'
                            )}
                          >
                            {item.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div> 
          </div>
        </div> */}
        <div className="flex rowkindle-content mt-6">
          {/* <a href="/app/kindledetail">  
            <div className="cardHolder rounded-[14px] overflow-hidden mb-3 break-inside">
              <div className="flex justify-between w-full px-4 pt-3 pb-1">
                <div className="inputCheck">
                  <input type="checkbox" />
                </div>                        
              </div>
              <div className="cardHeader flex items-center pb-2 px-4">
                <div className="Cardtitle w-3/4">
                  <h3 className="social-textcontent">The ART of Fashion Journal by Eila Mell</h3>
                </div>
                <div className="iconsRight flex iconsalign-content">
                  <FontAwesomeIcon className="vertical-iconcontent text-[#707070] text-[22px] ml-4" icon={faEllipsisVertical} />
                </div>
              </div>
              <div className="kindle-card"> 
                <span>Highlights: 2</span>
                <img className="w-full kindleimg-content" src={ArtBook} alt="artFashion"/>
                <div className="kindlewhole-bgcontent flex w-full">
                  <div className="kindlelist-box bg-green w-[50%]"></div>
                  <div className="kindlelist-box ml-[2px] bg-afc604 w-[50%]"></div>
                </div> 
            </div>
            </div>
          </a>  */}

              {kindles?.bookList?.map((kindle) => {
                      return (
                        <a href={`/app/kindledetail/${kindle._id}`}>
                          <div className="cardHolderskindle">             
                            <div className="kindle-card"> 
                              <div className="kindlenumber-content">{kindle?.totalHighlightOfBook}</div>
                              <div className="kindleemail-content" onClick={(event) => handleFocusClick(event,kindle._id,kindle.isFocused ? kindle.isFocused : false)}><img src={kindle.isFocused? FocusBucket :EmailGrey} alt="emailGrey" /></div>
                              <img className="kindleimg-content" src={kindle?.image} alt="artBook"/>
                              <div className="kindlewhole-bgcontent flex">
                              {kindle.colors?.map((color,index) => {
                                  return(
                                    <div className="kindlelist-box bg-green" style={{backgroundColor:color.trim().toLowerCase() == 'yellow' ? '#F2E366' : color, marginLeft: index > 0 ? "2px" : 0, width: kindle.colors.length == 4 ? '24px' : kindle.colors.length == 3 ? '32.33px' : kindle.colors.length == 2 ? '50px' : '102px'}}></div>
                                  );
                              })}
                                
                                {/* <div className="kindlelist-box ml-[2px] bg-afc604"></div> */}
                              </div> 
                              <div className="cardHeader flex items-center">
                                <div className="tooltip">
                                  <span className="tooltipkindle-text bucket">
                                    <p>{kindle?.title}</p>
                                    <p>{kindle?.authorName}</p>
                                  </span>
                                  <div className="Cardtitle">
                                    <h3 className="social-textcontent">{decodeHtmlEntities(kindle?.title)}</h3>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="highlight-content">Highlights: 2</div> */}
                            </div>
                          </div>
                        </a>
                      );
              })}
        </div>
      </div>
      <div className="kindlelist-mblsection">
      <div className="kindleHeader">
          <div className="flex justify-between mb-3">
            <div className="flex items-center justify-end"> 
              <h2 className="text-xl mybuckets-content dark:text-[#fff]">Kindle Highlights</h2>
              <div className="kindlehighlight-content mr-4"><span className="kindlebg-content background-primary text-white rounded-full ml-4">{kindles?.bookList?.length}</span></div>
                  <Menu as="div" className="relative flex-shrink-0">
                <div>
                  <Menu.Button className="flex rounded-full focus:outline-none ">
                    <div href="#" className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500">
                      <strong><FontAwesomeIcon className="vertical-iconcontent text-[#707070] text-[22px] mr-4" icon={faEllipsisVertical} /></strong>
                    </div>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">                           
                    {filterDropDownselect.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <a
                            onClick={()=>{handleFilterSelectText(item.name)}}
                            href={item.href}
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block py-2 px-4 text-[13px] text-gray-700'
                            )}
                          >
                            {item.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
                        
            </div>
        </div>
        <div className="flex justify-between mb-3">
              {
                kindles?.bookList?
                kindles?.bookList?.length?
                <a style={{filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))',cursor:'pointer'}} className="buttonpadding-content mx-8 text-[16px] rounded-full bg-[#FF6600] text-white pr-[32px]" onClick={handleSyncPopup}>
                <FontAwesomeIcon className="pl-[23px] pr-[17.73px]" icon={fontIcons.faPlus} />
                <span className="createbutton-context">Sync Highlights</span>
              </a>
                :
                <a href="https://read.amazon.com/kp/notebook?myhighlight" style={{filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))'}} className="buttonpadding-content mx-8 text-[16px] rounded-full bg-[#FF6600] text-white pr-[32px]">
                <FontAwesomeIcon className="pl-[23px] pr-[17.73px]" icon={fontIcons.faPlus} />
                <span className="createbutton-context">Sync Highlights</span>
              </a>
                 : 
                 <a href="https://read.amazon.com/kp/notebook?myhighlight" style={{filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))'}} className="buttonpadding-content mx-8 text-[16px] rounded-full bg-[#FF6600] text-white pr-[32px]">
                    <FontAwesomeIcon className="pl-[23px] pr-[17.73px]" icon={fontIcons.faPlus} />
                    <span className="createbutton-context">Sync Highlights</span>
                  </a>
              }
              <div className="ml-auto" style={{marginRight:"5px"}}>
                <div className="ml-[auto] flex items-center justify-end">
                  {/* <span className="sorttext-content mx-3 dark:text-[#acacac]">Sort</span> */}
                  <Menu as="div" className="relative flex-shrink-0">
                    <div>
                      <Menu.Button className="flex rounded-full focus:outline-none ">
                        <div href="#" className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500">
                          <strong className="createdtext-content pr-4">{cardLabelDefault}</strong>
                          <FontAwesomeIcon style={{fontSize:'12px',color:'#707070'}} icon={faChevronDown} />
                        </div>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">                           
                        {filterDropDown.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <a
                                onClick={()=>{handleFilterText(item.name)}}
                                href={item.href}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block py-2 px-4 text-[13px] text-gray-700'
                                )}
                              >
                                
                                {cardLabelDefault == item.name ? item.name : item.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div> 
              </div>  
          </div>
              <div className="flex justify-between mb-3">
              <div className="text-[14px] lg:text-[17px]"><a className="flex items-center" href="/"><img src={CalenderIcon} alt='calenderIcon' className="w-[22px] dark-mode-icon"/> <span className="createdtext-content pl-2 dark:text-[#bbbbbb]">Last synced on {kindles?.lastKindleSync? moment(kindles?.lastKindleSync).format(
                        "MMM D, YYYY @ hh:mm A"
                      ) : "---"}</span></a> </div>
              </div>
        </div>
        <hr />
        <div className="flex rowkindle-content mt-6">
              {kindles?.bookList?.map((kindle) => {
                      return (
                        <a href={`/app/kindledetail/${kindle._id}`}>
                          <div className="cardHolderskindle">             
                            <div className="kindle-card"> 
                              <div className="kindlenumber-content">{kindle?.totalHighlightOfBook}</div>
                              <div className="kindleemail-content"><img src={EmailGrey} alt="emailGrey" /></div>
                              <img className="kindleimg-content" src={kindle?.image} alt="artBook"/>
                              <div className="kindlewhole-bgcontent flex">
                              {kindle.colors?.map((color,index) => {
                                  return(
                                    <div className="kindlelist-box bg-green" style={{backgroundColor:color.trim().toLowerCase() == 'yellow' ? '#F2E366' : color, marginLeft: index > 0 ? "2px" : 0, width: kindle.colors.length == 4 ? '24px' : kindle.colors.length == 3 ? '32.33px' : kindle.colors.length == 2 ? '50px' : '102px'}}></div>
                                  );
                              })}
                              </div> 
                              <div className="cardHeader flex items-center">
                                <div className="tooltip">
                                  <span className="tooltipkindle-text bucket">
                                    <p>{kindle?.authorName}</p>
                                  </span>
                                  <div className="Cardtitle">
                                    <h3 className="social-textcontent">{decodeHtmlEntities(kindle?.title)}</h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      );
              })}
        </div>
      </div>
    </div>
     )}
     {invitepopup &&
        <div className="filesuploadpopup relative !z-[999]" style={{ height: "100%" }}>
          <div className="subinviteupload-section">
            <div className="teaminvitesection flex">
              <div className="teammembers-content">Delete all books</div>
              <div className="dismissSubfiles-content" onClick={closeInvitePopup}><img src={DismissButton} alt="dismissButton" /></div>
            </div>
            <hr/>
            <div className="actiontext-content">This action cannot be undone. This will delete all books and highlights. Are you sure you want to delete your books?</div>
            <div className="flex justify-end lastfile-section">
              <div className="cancelteam-content" onClick={closeInvitePopup} style={{marginRight: "10px"}}>Cancel</div>
              <button className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white justify-end">
                <span className="saveteambutton-context" onClick={deleteUserAccount}>Yes</span>
              </button>
            </div>
          </div>
        </div> 
      }
       {syncpopup &&
        <div className="filesuploadpopup relative !z-[999]" style={{ height: "100%" }}>
          <div className="subinviteupload-section">
            <div className="teaminvitesection flex">
              <div className="teammembers-content">Sync Kindle Highlights</div>
              <div className="dismissSubfiles-content" onClick={closeSyncPopup}><img src={DismissButton} alt="dismissButton" /></div>
            </div>
            <hr/>
            <div className="actiontext-content"><p style={{marginBottom:"10px"}}>Click YES to sync and update your new Kindle highlights and books manually.</p><p>After the first initial sync, you do not need to sync manually. Zimzee will automatically sync any new highlights in the background.</p></div>
            <div className="flex justify-end lastfile-section">
              <div className="cancelteam-content" onClick={closeSyncPopup} style={{marginRight: "10px"}}>Cancel</div>
              <a className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white justify-end" href="https://read.amazon.com/kp/notebook?myhighlight">
                <span className="saveteambutton-context">Yes</span>
              </a>
            </div>
          </div>
        </div> 
      }
      {stopSyncpopup &&
        <div className="filesuploadpopup relative !z-[999]" style={{ height: "100%" }}>
          <div className="subinviteupload-section">
            <div className="teaminvitesection flex">
              <div className="teammembers-content">{kindleAutoSync ? 'Stop' : 'Start'} Kindle Sync</div>
              <div className="dismissSubfiles-content" onClick={closeStopSyncPopup}><img src={DismissButton} alt="dismissButton" /></div>
            </div>
            <hr/>
            <div className="actiontext-content"><p style={{marginBottom:"10px"}}>This will {kindleAutoSync ? 'stop' : 'start'} syncing your Kindle books automatically in the background.</p> <p>Click YES to confirm.</p></div>
            <div className="flex justify-end lastfile-section">
              <div className="cancelteam-content" onClick={closeStopSyncPopup} style={{marginRight: "10px"}}>Cancel</div>
              <button className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white justify-end">
                <span className="saveteambutton-context" onClick={updateKindleStatus}>Yes</span>
              </button>
            </div>
          </div>
        </div> 
      }
      <ToastContainer/>
     </>
  );
}

export default KindleList;